import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useAuth } from "../../Providers/AuthProvider";
import { InputAdornment, InputLabel, Pagination, Select, MenuItem, FormControl, TextField, FormControlLabel, Checkbox } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Swal from "sweetalert2";
import { useWs } from "../../Providers/EmbalajeProvider";
import CarritoEmbalajeTable from "./Tablas/CarritoEmbalajeTabla";
import { listar_cuentas_empresa } from "../../Globales/MetodosAPIs";

const Embalaje = () => {
  const { ultimoMensajeGeneral, setEnviar } = useWs();
  const { authTokens } = useAuth();
  const [carritosAsignados, setCarritosAsignados] = useState([]);
  const [selectedTipoEnvio, setSelectedTipoEnvio] = useState('all');
  const [refresh, setRefresh] = useState(0);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
  const [filteredCarritos, setFilteredCarritos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCuenta, setSelectedCuenta] = useState('all');
  const [accounts, setAccounts] = useState([]);
  const [showDelayed, setShowDelayed] = useState(false);
  const [dateFilter, setDateFilter] = useState('today');

  const carritosRecibidosRef = useRef(false);
  const retryTimeoutRef = useRef(null);

  const pageSize = 15;

  const dateOptions = [
    { id: "today", desc: "Para Hoy" },
    { id: "tomorrow", desc: "Para Mañana" },
  ];

  const options = [
    { id: "all", desc: "Todos los Envios" },
    { id: "fulfillment", desc: "Envio Full" },
    { id: "self_service", desc: "Envio Flex" },
    { id: "cross_docking", desc: "Colecta" },
    { id: "acuerdo_con_comprador", desc: "Acuerdo con Comprador" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cuentas = await listar_cuentas_empresa(authTokens.accessToken);
        setAccounts(cuentas.data);
      } catch (error) {
        console.error('Error al obtener las cuentas:', error);
      }
    };
    fetchData();
  }, [authTokens.accessToken]);

  const solicitarCarritosAsignados = (page = 1, tipoEnvio = selectedTipoEnvio) => {
    setEnviar({
      action: "mis_carritos_asignados",
      values: {
        page_number: page,
        page_size: pageSize,
        tipo_envio: tipoEnvio,
        cuenta: selectedCuenta !== 'all' ? selectedCuenta : null,
        delayed: showDelayed,
        date_filter: showDelayed ? null : dateFilter
      }
    });
  };

  useEffect(() => {
    solicitarCarritosAsignados(pageNumber, selectedTipoEnvio);
  }, [pageNumber, pageSize, selectedTipoEnvio, selectedCuenta, showDelayed, dateFilter]);

  useEffect(() => {
    if (ultimoMensajeGeneral) {
      if (ultimoMensajeGeneral.action === 'carritos_asignados') {
        setCarritosAsignados(ultimoMensajeGeneral.values);
        setTotalPages(ultimoMensajeGeneral.pagination.total_pages);
        carritosRecibidosRef.current = true;

        if (retryTimeoutRef.current) {
          clearTimeout(retryTimeoutRef.current);
        }
      } else {
        const swalOptions = {
          title: ultimoMensajeGeneral.status === 'error' ? 'Error' : 'Éxito',
          text: ultimoMensajeGeneral.message,
          icon: ultimoMensajeGeneral.status === 'error' ? 'error' : 'success',
          confirmButtonText: 'Ok'
        };
        Swal.fire(swalOptions);
      }
    }
  }, [ultimoMensajeGeneral]);

  useEffect(() => {
    const filtered = carritosAsignados
      .filter(carrito => selectedTipoEnvio === 'all' || carrito.detalles_envio.tipo_de_envio === selectedTipoEnvio)
      .filter(carrito => {
        const query = searchQuery.toLowerCase();
        return carrito.id.toString().includes(query) || carrito.ventas.some(venta => venta.id.toString().includes(query));
      });

    setFilteredCarritos(filtered);
  }, [carritosAsignados, selectedTipoEnvio, searchQuery]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDelayedChange = (event) => {
    setShowDelayed(event.target.checked);
    if (event.target.checked) {
      setDateFilter('today');
    }
  };

  return (
    <Container maxWidth="lg" style={{ height: '100vh' }}>
      <Box sx={{ height: '100%', width: "100%", display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: '10%', p: 3, width: "100%" }}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
            <TextField
              sx={{ flexGrow: 1 }}
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Buscar por ID de Carrito o Venta"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
              <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                <InputLabel>Cuenta</InputLabel>
                <Select
                  value={selectedCuenta}
                  onChange={e => setSelectedCuenta(e.target.value)}
                  label="Cuenta"
                >
                  <MenuItem value="all">Todas las Cuentas</MenuItem>
                  {accounts.map(account => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.usuario}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                <InputLabel>Envío</InputLabel>
                <Select
                  value={selectedTipoEnvio}
                  onChange={e => setSelectedTipoEnvio(e.target.value)}
                  label="Envío"
                >
                  {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                <InputLabel>Fecha Envio</InputLabel>
                <Select
                  value={dateFilter}
                  onChange={e => setDateFilter(e.target.value)}
                  label="Fecha Envio"
                  disabled={showDelayed}
                >
                  {dateOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDelayed}
                    onChange={handleDelayedChange}
                  />
                }
                label="Solo Demoradas"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: '90%', p: 3, width: "100%" }}>
          <Paper elevation={8} sx={{ height: '100%', maxHeight: "600px", width: "100%" }}>
            <CarritoEmbalajeTable
              carritos={filteredCarritos}
              setRefresh={setRefresh}
              filasSeleccionadas={filasSeleccionadas}
              setFilasSeleccionadas={setFilasSeleccionadas}
            />
            <Pagination count={totalPages} page={pageNumber} onChange={handlePageChange} />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default Embalaje;